import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class AssemblyBOMProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('assembly-bom', query)
  }

  getItemsByParent (items) {
    this.setHeader(getAuthToken())
    return this.get('assembly-bom/parent', { items })
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`assembly-bom/${id}`)
  }

  createItem (payload) {
    this.setHeader(getAuthToken())
    return this.post('assembly-bom', payload)
  }

  updateItem (payload) {
    this.setHeader(getAuthToken())
    return this.put(`assembly-bom/${payload.id}`, payload)
  }

  deleteItem (id) {
    this.setHeader(getAuthToken())
    return this.delete(`assembly-bom/${id}`)
  }
}

export default AssemblyBOMProvider
