<template>
  <v-container
    fluid
    class="container-bg rounded elevation-3">
    <v-row justify-md="space-between">
      <v-col
        cols="12"
        md="4">
        <h2>Assembly BOM</h2>
      </v-col>

      <v-spacer />

      <v-col
        cols="12"
        md="4">
        <div class="d-flex flex-row justify-end align-center">
          <search-box
            v-model="query.search"
            @on-search="getItems(true)" />
          <v-btn
            color="secondary"
            :disabled="loading"
            to="/purchases/assembly-bom/create"
            class="ml-2 mb-1">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :item-per-page="query.limit"
          :loading="loading"
          hide-default-footer>
          <template #[`item.bcSync`]="{ item }">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-icon
                  v-if="item.bcSync && item.bcSync.sync && item.bcSync.bcLog"
                  v-bind="attrs"
                  color="success"
                  v-on="on">
                  mdi-check-circle-outline
                </v-icon>
                <v-icon
                  v-else
                  v-bind="attrs"
                  color="error"
                  v-on="on">
                  mdi-sync-alert
                </v-icon>
              </template>
              <span v-if="item.bcSync && item.bcSync.updatedAt">
                Last sync date {{ item.bcSync.updatedAt | dateTimeUserFormat() }}
              </span>
              <span v-else>
                Not sync yet
              </span>
            </v-tooltip>
          </template>
          <template #[`item.imageURL`]="{ item }">
            <img
              :src="item.imageURL || noPicture"
              style="max-height: 80px;"
              class="mx-auto my-2" />
          </template>
          <template #[`item.action`]="{ item }">
            <v-btn
              color="secondary"
              small
              icon
              :to="`/purchases/assembly-bom/${item.id}`">
              <v-icon small>
                mdi-square-edit-outline
              </v-icon>
            </v-btn>
            <v-btn
              color="error"
              small
              icon
              @click="confirmRemoveItem(item)">
              <v-icon small>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-pagination
          v-model="query.page"
          :length="pageCount"
          :disabled="loading"
          total-visible="7"
          color="secondary" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import SearchBox from '@/components/SearchBox.vue'
import AssemblyBOMProvider from '@/resources/AssemblyBOMProvider'
import NoPicture from '@/assets/image/no_picture_available.png'

const AssemblyBOMService = new AssemblyBOMProvider()

export default {
  components: {
    SearchBox
  },
  data () {
    return {
      headers: [
        {
          text: '',
          value: 'bcSync',
          align: 'center',
          sortable: false
        },
        {
          text: 'รูปภาพ',
          value: 'imageURL',
          align: 'center',
          sortable: false
        },
        {
          text: 'รหัสสินค้า',
          value: 'parentItemNo',
          align: 'center',
          sortable: false
        },
        {
          text: 'ชื่อสินค้า',
          value: 'description',
          align: 'center',
          sortable: false
        },
        {
          text: 'ตัวเลือก',
          value: 'action',
          align: 'center',
          sortable: false
        }
      ],
      items: [],
      query: {
        page: 1,
        limit: 10,
        search: ''
      },
      pageCount: 0,
      loading: false,
      noPicture: NoPicture
    }
  },
  watch: {
    'query.page': {
      handler () {
        this.getItems()
      },
      deep: true
    }
  },
  mounted () {
    this.getItems(true)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal'
    }),
    async getItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []
        }

        const { data } = await AssemblyBOMService.getItems({
          page: this.query.page,
          limit: this.query.limit,
          search: this.query.search
        })

        this.items = data.results
        this.pageCount = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    confirmRemoveItem (item) {
      this.setModal({
        value: true,
        title: 'Remove Item',
        message: `Do you want to remove ${item.parentItemNo}?`,
        confirmText: 'Remove',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.removeItem(item.id)
      })
    },
    async removeItem (id) {
      try {
        this.loading = true

        await AssemblyBOMService.deleteItem(id)

        this.getItems(true)
      } catch (error) {
        console.error('removeItem', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.container-bg {
  background-color: white;
}
</style>
